export default {
    components: {},
    data() {
        return {};
    },
    methods: {},
    created() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
